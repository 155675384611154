<template>
  <div v-if="item.type === 'text'">
    <pop-up-text-group :item="item"></pop-up-text-group>
  </div>
  <div v-else-if="item.type === 'table'">
    <pop-up-table :item="item"></pop-up-table>
  </div>
  <div v-else-if="item.type === 'datatable'">
    <PopUpDataTable :item="item"></PopUpDataTable>
  </div>
  <div v-else-if="item.type === 'timeline'">
    <pop-up-time-line :item="item"></pop-up-time-line>
  </div>
  <div v-else-if="item.type === 'docs'">
    <PopUpDocs :item="item"></PopUpDocs>
  </div>
</template>

<script>
import PopUpTable from "@/own/components/fulfillment/asn/popup/PopUpTable.vue";
import PopUpDataTable from "@/own/components/fulfillment/asn/popup/PopUpDataTable.vue";
import PopUpTextGroup from "@/own/components/fulfillment/asn/popup/PopUpTextGroup.vue";
import PopUpDocs from "@/own/components/fulfillment/asn/popup/PopUpDocs.vue";
import PopUpTimeLine from "@/own/components/fulfillment/asn/popup/PopUpTimeLine.vue";
export default {
  name: "PopUpSelector",
  props: ["item"],
  components: {
    PopUpTable,
    PopUpTextGroup,
    PopUpTimeLine,
    PopUpDocs,
    PopUpDataTable,
  },
};
</script>

<style></style>
